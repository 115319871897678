import { MenuItem, Order } from './order';
import { filter } from 'rxjs/operators';
import { Constants } from '../constants';
import * as moment from 'moment';

export class Account {
  company: string;
  token: string;
  address: string;
  city: string;
  state: string;
  zip_code: string;
  ftp_username: string;
}
export class Store {
  disclaimer_from_time: any;
  disclaimer_show: any;
  pause_store: any;
  store_pause_time: any;
  store_pause_date_time: any;
  pause_store_whole_day: any;
  store_whole_day_pause_date_time: any;
  is_spot_field_mandatory: any;
  disclaimer_to_time(arg0: string, disclaimer_to_time: any) {
    throw new Error("Method not implemented.");
  }
  id: string;
  account: Account;
  store_name: string;
  store_number: number;
  address: string;
  city: string;
  state_geographic: string;
  zip_code: string;
  phone: string;
  sms_number: string;
  sms_webhook: string;
  time_zone: string;
  contact_name: string;
  has_order_time_now: boolean;
  has_order_time_later: boolean;
  has_order_occasion_pickup: boolean;
  has_order_occasion_delivery: boolean;
  has_order_occasion_dine_in: boolean;
  has_order_occasion_curbside: boolean;
  has_order_occasion_tableside: boolean;
  pickup_dine_in_lead_time: number;
  delivery_lead_time: number;
  enable_cash_payment: boolean;
  working: Map<number, WorkingDay>;
  url_slug: string;
  has_card_payment: boolean;
  default_view_text_order_menu: boolean;
  has_menu: boolean;
  enable_text_ordering: boolean;
  enable_pick_and_click_ordering: boolean;
  delivery_minimum: number;
  payment_gateway: string;
  dynamic_offer_url: string;
  toggle_tip: boolean;
  gmb_location_id: string;
  delivery_radius: number;
  feature_flag: Map<string, boolean>;
  lead_times: any[];
  service_toggle: boolean;
  surcharge_percent: number;
  tip_percent_option: any[];
  last_order_buffer: Map<number, number>;
  occasion_flags: Map<string, boolean>;
  payroc_username: string;
  service_charge_label: string;
  surcharge_label: string;
  service_charge_description: string;
  special_hour: any[];
  holiday_list: any[];
  delivery_charge: number;
  nmi_public_key: string;
  surcharge_text: string;
  enable_pos_pay_later: boolean;
  ui_settings: Map<string, boolean>
  show_menu_price: boolean;
  modules: string[];
  enable_pos_credit_service_charge_dollar: boolean;
  enable_online_credit_service_charge_dollar: boolean;
  surcharge_label_dollar: any;
  disclaimer_message: string;
  count_down_timer_color: string;
  store_description: string;

  hasModule(module) {
    return this.modules.includes(module)
  }

  convertTZ(date) {
    return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", { timeZone: this.time_zone }));
  }

  isStoreOpened() {
    return this.is_open_now();
  }

  // Holiday_list
  getHolidayHours() {
    let today = moment().format("YYYY-MM-DD");
    let holiday = this.holiday_list.find(h => h.holiday_date === today);
    if (holiday) {
      let ret = holiday['hour'];
      if (!ret) {
        ret = this.getTodaysWorkingConfig();
      }
      ret['is_open'] = holiday['is_open'];
      return ret;
    } else {
      return null;
    }
  }

  isStoreOpenedNeedFix() {
    var format = "hh:mm";
    let special_hour = this.getSpecialHours()
    let holiday_list = this.getHolidayHours();

    const currentDayIndex = moment().day();
    const previousDaySlot = this.getSlot(currentDayIndex - 2);

    const currentDaySlot = special_hour ? special_hour : holiday_list ? holiday_list : this.getSlot(currentDayIndex - 1);

    // converted currenttimezone according to required timezone in api response
    const convertedTimezone = this.convertTZ(new Date());

    //converted in a time format-"hh:mm"
    const currentTime = moment(convertedTimezone.getHours() + ":" + convertedTimezone.getMinutes(), format);
    const beforeTime_today = moment(currentDaySlot.from_hour + ":" + currentDaySlot.from_min, format);
    let afterTime_today = moment(currentDaySlot.to_hour + ":" + currentDaySlot.to_min, format);
    const beforeTime_previous = moment(previousDaySlot.from_hour + ":" + previousDaySlot.from_min, format);
    let afterTime_previous = moment(previousDaySlot.to_hour + ":" + previousDaySlot.to_min, format);

    //adding/substracting one day because closing time is of tommorrow(one day extra)
    if (afterTime_previous < beforeTime_previous) {
      afterTime_previous = moment(afterTime_previous, "DD-MM-YYYY").subtract(1, 'days');
    }
    if (afterTime_today < beforeTime_today) {
      afterTime_today = moment(afterTime_today, "DD-MM-YYYY").add(1, 'days');
    }

    if (currentTime.isBetween(beforeTime_previous, afterTime_previous)) {
      return true
    } else if (currentTime.isBetween(beforeTime_today, afterTime_today)) {
      return true;
    } else {
      return false;
    }
  }

  getServiceChargeLabel() {
    return this.service_charge_label && this.service_charge_label.length > 0 ? this.service_charge_label : 'Service Fee'
  }

  getSurchargeLabel() {
    if (this.enable_pos_credit_service_charge_dollar || this.enable_online_credit_service_charge_dollar) {
      return this.surcharge_label_dollar && this.surcharge_label_dollar.length > 0 ? this.surcharge_label_dollar : 'Surcharge'
    } else {
      return this.surcharge_label && this.surcharge_label.length > 0 ? this.surcharge_label : 'Surcharge'
    }
  }

  getTodayIndex() {
    let today = new Date()
    var dayIndex = today.getDay() - 1;
    if (dayIndex < 0) {
      dayIndex = 6;
    }
    return dayIndex;
  }

  getSpecialHours() {
    let today = moment().format("YYYY-MM-DD")
    // let special_date = this.special_hour.find(h => h.special_date === today)
    let special_date = this.special_hour.find(h => new Date(h.special_date) <= new Date(today) && new Date(today) <= new Date(h.special_end_date))
    if (special_date) {
      let ret = special_date['hour']
      if (!ret) {
        ret = this.getTodaysWorkingConfig()
      }
      ret['is_open'] = special_date['is_open']
      return ret
    } else {
      return null
    }
  }

  getSlot(dayIndex) {
    let workingHours = this.working && this.working[dayIndex]['hours']
    var slot;
    let now = new Date()
    if (workingHours.length === 1) {

      const fr_Time = new Date();
      fr_Time.setHours(workingHours[0].from_hour, workingHours[0].from_min, 0, 0);
      const to_Time = new Date();
      to_Time.setHours(workingHours[0].to_hour, workingHours[0].to_min, 0, 0);

      if (this.working[dayIndex]['open_type'] !== 'CLOSED') {
        slot = workingHours[0]
        slot['day_index'] = dayIndex;

      } else {
        slot = this.findNextOpenSlot();
      }
    } else {
      if (this.working[dayIndex]['open_type'] !== 'CLOSED') {
        const todayIndex = now.getDay() - 1;
        if (dayIndex == todayIndex) {
          slot = workingHours.find(w => {
            const fromTime = new Date();
            fromTime.setHours(w.from_hour, w.from_min, 0, 0);
            const toTime = new Date();
            toTime.setHours(w.to_hour, w.to_min, 0, 0);
            if (now >= fromTime && now <= toTime) {
              return w
            } else if (now <= toTime) {
              return w
            } else if (now > toTime) {
              return
            }
          })
        }

        if (!slot) {
          slot = workingHours[workingHours.length - 1]
        }
        slot['day_index'] = dayIndex
      } else {
        slot = this.findNextOpenSlot();
      }
    }
    slot['is_open'] = this.working[dayIndex]['open_type'] !== 'CLOSED'
    return slot;
  }

  findNextOpenSlot() {
    const workingDays = Object.values(this.working);
    const today = new Date().getDay() - 1;
    let nextOpeningDay = null;
    let currentIndex = today + 1;
    let slot = null;

    for (; currentIndex !== today; currentIndex = (currentIndex + 1) % 7) {
      const nextDayIndex = currentIndex % 7;
      const nextDay = workingDays.find(day => day.day === nextDayIndex);
      if (nextDay && nextDay.open_type === 'OPEN') {
        nextOpeningDay = nextDay;
        const nextDayWorkingHour = this.working && this.working[nextDayIndex]['hours'];
        slot = nextDayWorkingHour[0];
        slot['day_index'] = nextOpeningDay.day;
        break;
      }
      if (nextDay && nextDay.open_type !== 'CLOSED') {
        break;
      }
    }

    return slot;
  }

  getYesterdaysWorkingConfig() {
    let today = new Date()
    var dayIndex = today.getDay() - 2;
    if (dayIndex < 0) {
      dayIndex = 6;
    }
    return this.getSlot(dayIndex)
  }

  getPreviousWorkingConfig(date) {
    var dayIndex = date.getDay() - 2;
    if (dayIndex < 0) {
      dayIndex = 5;
    }
    return this.getSlot(dayIndex)
  }

  getSpecialHoursOn(date) {
    let today = moment(date).format("YYYY-MM-DD")
    let special_date = this.special_hour.find(h => h.special_date === today)
    if (special_date) {
      let ret = special_date['hour']
      if (!ret) {
        ret = this.getTodaysWorkingConfig()
      }
      ret['is_open'] = special_date['is_open']
      return ret
    } else {
      return null
    }
  }


  getTodaysWorkingConfig() {
    let today = new Date()
    var dayIndex = today.getDay() - 1;
    if (dayIndex < 0) {
      dayIndex = 6;
    }
    return this.getSlot(dayIndex)
  }

  getWorkingConfigOn(date) {
    var dayIndex = date.getDay() - 1;
    if (dayIndex < 0) {
      dayIndex = 6;
    }
    return this.getSlot(dayIndex)
  }

  isAvailable() {
    return this.service_toggle
  }

  is_closed_for_the_day(date){
    let special_hour = this.getSpecialHoursOn(date);
    let day_config = special_hour ? special_hour : this.getWorkingConfigOn(date);
    return day_config.is_open === false
  }

  is_open_on(date, leadTime = 0){

    let now = moment(date).toDate();
    let yesterday_config = this.getPreviousWorkingConfig(date);
    let special_hour = this.getSpecialHoursOn(date);

    let day_config = special_hour ? special_hour : this.getWorkingConfigOn(date);
    let start = moment(date).toDate();
    if (moment(start).isSame(moment().toDate(), 'day')) {
      if (moment().isAfter(moment(day_config.from_hour, 'HH:mm'))) {
        start.setHours(moment().hour());
        start.setMinutes(moment().minute());
        start = moment(start).add(leadTime, 'minutes').toDate();
      } else {
        start.setHours(day_config.from_hour);
        start.setMinutes(day_config.from_min);
      }
    } else {
      start.setHours(day_config.from_hour);
      start.setMinutes(day_config.from_min);
    }

    let end = moment(date).toDate();
    end.setHours(day_config.to_hour);
    end.setMinutes(day_config.to_min);

    if (yesterday_config.from_hour > yesterday_config.to_hour && now.getHours() < yesterday_config.to_hour) {
      start.setDate(start.getDate() - 1)
    }

    if (day_config.from_hour > day_config.to_hour) {
      end.setDate(end.getDate() + 1)
    }
    end = moment(end).subtract(leadTime, 'minutes').toDate();
    return day_config.is_open && start <= now && end >= now;
  }


  // is_open_now() {
  //   let now = new Date()
  //   let yesterday_config = this.getYesterdaysWorkingConfig()
  //   let special_hour = this.getSpecialHours()
  //   let holiday_list = this.getHolidayHours()

  //   let fromDate = new Date();
  //   fromDate.setDate(fromDate.getDate() - 1); // Set to yesterday
  //   fromDate.setHours(yesterday_config.from_hour, yesterday_config.from_min, 0, 0);

  //   let toDate = new Date();
  //   toDate.setDate(toDate.getDate() - 1); // Set to yesterday
  //   toDate.setHours(yesterday_config.to_hour, yesterday_config.to_min, 0, 0);

  //   if (toDate < fromDate) {
  //     toDate.setDate(toDate.getDate() + 1); // Move to_date to the next day
  //   }
  //   let day_config = special_hour ? special_hour : holiday_list ? holiday_list : this.getTodaysWorkingConfig();

  //   const currentDayIndex = this.getTodayIndex();
  //   if (currentDayIndex == day_config.day_index) {
  //     let start = new Date()
  //     start.setHours(day_config.from_hour)
  //     start.setMinutes(day_config.from_min)
  //     let end = new Date()
  //     end.setHours(day_config.to_hour)
  //     end.setMinutes(day_config.to_min)
  //     if (end < start) {
  //       end.setDate(end.getDate() + 1);
  //     }

  //     const systemTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  //     if (toDate.toISOString().split('T')[0] === start.toISOString().split('T')[0]
  //       && toDate.getTime() <= start.getTime()) {

  //       if (now < toDate) {
  //         return yesterday_config.is_open && toDate > now && start > now && this.time_zone === systemTimezone;
  //       } else {
  //         return day_config.is_open && start <= now && end >= now && this.time_zone === systemTimezone;
  //       }
  //     }
  //     else {
  //       return day_config.is_open && start <= now && end >= now && this.time_zone === systemTimezone;
  //     }
  //   } else if (special_hour) {
  //     let start = new Date()
  //     start.setHours(day_config.from_hour)
  //     start.setMinutes(day_config.from_min)
  //     let end = new Date()
  //     end.setHours(day_config.to_hour)
  //     end.setMinutes(day_config.to_min)
  //     if (end < start) {
  //       end.setDate(end.getDate() + 1);
  //     }

  //     const systemTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  //     if (toDate.toISOString().split('T')[0] === start.toISOString().split('T')[0]
  //       && toDate.getTime() <= start.getTime()) {

  //       if (now < toDate) {
  //         return yesterday_config.is_open && toDate > now && start > now && this.time_zone === systemTimezone;
  //       } else {
  //         return day_config.is_open && start <= now && end >= now && this.time_zone === systemTimezone;
  //       }
  //     }
  //     else {
  //       return day_config.is_open && start <= now && end >= now && this.time_zone === systemTimezone;
  //     }
  //   } else {
  //     return false
  //   }

  // }

  is_open_now() {
    let now = new Date();
    let yesterday_config = this.getYesterdaysWorkingConfig();
    let special_hour = this.getSpecialHours();
    let holiday_list = this.getHolidayHours();

    let fromDate = this.createDateFromConfig(yesterday_config.from_hour, yesterday_config.from_min, -1);
    let toDate = this.createDateFromConfig(yesterday_config.to_hour, yesterday_config.to_min, -1);

    if (toDate < fromDate) {
      toDate.setDate(toDate.getDate() + 1); // Adjust if crossing midnight
    }

    let day_config = special_hour ? special_hour : holiday_list ? holiday_list : this.getTodaysWorkingConfig();

    const systemTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const currentDayIndex = this.getTodayIndex();

    if (currentDayIndex === day_config.day_index) {
      return this.isWithinWorkingHours(day_config, now, toDate, yesterday_config, systemTimezone);
    } else if (special_hour) {
      return this.isWithinWorkingHours(day_config, now, toDate, yesterday_config, systemTimezone);
    } else {
      return false;
    }
  }

  createDateFromConfig(hour, minute, daysOffset = 0) {
    let date = new Date();
    date.setDate(date.getDate() + daysOffset);
    date.setHours(hour, minute, 0, 0);
    return date;
  }

  resolveSpecialHourConfig(special_hour, now, defaultConfig) {
    if (!special_hour.is_open) {
      return defaultConfig;
    }
    let special_start = this.createDateFromConfig(special_hour.from_hour, special_hour.from_min);
    let special_end = this.createDateFromConfig(special_hour.to_hour, special_hour.to_min);

    if (special_start <= now && special_end >= now) {
      return special_hour;
    } else {
      return defaultConfig;
    }
  }

  isWithinWorkingHours(day_config, now, toDate, yesterday_config, systemTimezone) {
    let start = this.createDateFromConfig(day_config.from_hour, day_config.from_min);
    let end = this.createDateFromConfig(day_config.to_hour, day_config.to_min);

    if (end < start) {
      end.setDate(end.getDate() + 1); // Adjust if crossing midnight
    }

    if (toDate.toISOString().split('T')[0] === start.toISOString().split('T')[0] &&
      toDate.getTime() <= start.getTime()) {
      if (now < toDate) {
        return yesterday_config.is_open && toDate > now && start > now && this.time_zone === systemTimezone;
      } else {
        return day_config.is_open && start <= now && end >= now && this.time_zone === systemTimezone;
      }
    } else {
      return day_config.is_open && start <= now && end >= now && this.time_zone === systemTimezone;
    }
  }

  getLeadTime(occasion) {
    let leadTime = 0;
    let now = new Date();
    let dayIndex = this.getTodayIndex()
    if (occasion === 'PICKUP' || occasion === 'CURBSIDE') {
      leadTime = this.pickup_dine_in_lead_time;
    } else if (occasion === 'DELIVERY') {
      leadTime = this.delivery_lead_time;
    }

    let leadTimes: any[] = this.lead_times.filter(x => x.day === dayIndex && x.occasion === Constants.occasionRi[occasion])

    leadTimes.forEach(lt => {
      let start = new Date()
      start.setHours(lt.from_hour)
      start.setMinutes(lt.from_min)
      let end = new Date()
      end.setHours(lt.to_hour)
      end.setMinutes(lt.to_min)
      if (start < now && end > now) {
        leadTime = lt.lead_time;
      }
    });
    return leadTime;
  }

  getClosingHours() {
    let now = new Date()
    let yesterday_config = this.getYesterdaysWorkingConfig()
    let special_hour = this.getSpecialHours()
    let holiday_list = this.getHolidayHours()
    let day_config = special_hour ? special_hour : holiday_list ? holiday_list : this.getTodaysWorkingConfig();

    /* NEW */
    // if (special_hour && !special_hour.is_open) {
    //   day_config = this.getTodaysWorkingConfig();
    // } else if (special_hour && special_hour.is_open) {
    //   let special_start = new Date()
    //   special_start.setHours(day_config.from_hour)
    //   special_start.setMinutes(day_config.from_min)
    //   let special_end = new Date()
    //   special_end.setHours(day_config.to_hour)
    //   special_end.setMinutes(day_config.to_min)

    //   if (special_start <= now && special_end >= now) { } else {
    //     day_config = this.getTodaysWorkingConfig();
    //   }
    // }

    /* OLD */
    if (special_hour) {
      let special_start = new Date()
      special_start.setHours(day_config.from_hour)
      special_start.setMinutes(day_config.from_min)
      let special_end = new Date()
      special_end.setHours(day_config.to_hour)
      special_end.setMinutes(day_config.to_min)
    }

    let fromDate = new Date();
    fromDate.setDate(fromDate.getDate() - 1); // Set to yesterday
    fromDate.setHours(yesterday_config.from_hour, yesterday_config.from_min, 0, 0);

    let toDate = new Date();
    toDate.setDate(toDate.getDate() - 1); // Set to yesterday
    toDate.setHours(yesterday_config.to_hour, yesterday_config.to_min, 0, 0);

    if (toDate < fromDate) {
      toDate.setDate(toDate.getDate() + 1); // Move to_date to the next day
    }

    let start = new Date()
    start.setHours(day_config.from_hour)
    start.setMinutes(day_config.from_min)

    let end = new Date()
    end.setHours(day_config.to_hour)
    end.setMinutes(day_config.to_min)
    if (end < start) {
      end.setDate(end.getDate() + 1);
    }
    // / New Condition /
    if (toDate.toISOString().split('T')[0] === start.toISOString().split('T')[0]
      && toDate.getTime() <= start.getTime()) {
      if (now < toDate) {
        return toDate
      } else {
        return end
      }
    } else {
      return end
    }
  }

  getOpeningHours() {
    let now = new Date()
    let special_hour = this.getSpecialHours()
    let holiday_list = this.getHolidayHours()
    let day_config = special_hour ? special_hour : holiday_list ? holiday_list : this.getTodaysWorkingConfig();

    /* NEW */
    // if (special_hour && !special_hour.is_open) {
    //   day_config = this.getTodaysWorkingConfig();
    // } else if (special_hour && special_hour.is_open) {
    //   let special_start = new Date()
    //   special_start.setHours(day_config.from_hour)
    //   special_start.setMinutes(day_config.from_min)
    //   let special_end = new Date()
    //   special_end.setHours(day_config.to_hour)
    //   special_end.setMinutes(day_config.to_min)

    //   if (special_start <= now && special_end >= now) { } else {
    //     day_config = this.getTodaysWorkingConfig();
    //   }
    // }

    /* OLD */
    if (special_hour) {
      let special_start = new Date()
      special_start.setHours(day_config.from_hour)
      special_start.setMinutes(day_config.from_min)
      let special_end = new Date()
      special_end.setHours(day_config.to_hour)
      special_end.setMinutes(day_config.to_min)
    }

    const toTime = new Date(now.getFullYear(), now.getMonth(), now.getDate(), day_config.to_hour, day_config.to_min, 0);
    const fromTime = new Date(now.getFullYear(), now.getMonth(), now.getDate(), day_config.from_hour, day_config.from_min, 0);
    let today_config;
    if (now > fromTime && toTime <= now) {
      if (special_hour) {
        today_config = this.getTodaysWorkingConfig();
      } else {
        today_config = day_config
      }
      const dayconfig = this.getSlot(today_config.day_index + 1);
      now.setDate(now.getDate() + 1)
      now.setHours(dayconfig.from_hour)
      now.setMinutes(dayconfig.from_min)
      return now
    } else {
      if (!day_config.is_open) {
        if (special_hour) {
          today_config = this.getTodaysWorkingConfig();
        } else {
          today_config = day_config
        }
        const dayconfig = this.getSlot(today_config.day_index + 1);
        now.setDate(now.getDate() + 1)
        now.setHours(dayconfig.from_hour)
        now.setMinutes(dayconfig.from_min)
        return now
      } else {
        now.setDate(now.getDate() + 1)
        now.setHours(day_config.from_hour)
        now.setMinutes(day_config.from_min)
        return now
      }
      
    }

  }

  isEnabled(flag) {
    return flag in this.feature_flag && this.feature_flag[flag]
  }

  hasCurbsideOnly() {
    return this.has_order_occasion_curbside
      && !this.has_order_occasion_delivery
      && !this.has_order_occasion_dine_in
      && !this.has_order_occasion_pickup;
  }

  hasFutureOrderEnabled(){
    return this.isEnabled('future_order') && this.isEnabled('future_order');
  }



  isTodayHoliday() {
    let today = moment().format("YYYY-MM-DD");
    return this.holiday_list.some(h => h.holiday_date === today);
  }

  getCurrentSlot() {
    if (this.isTodayHoliday()) {
      return this.getHolidayHours();
    } else if (this.getSpecialHours()) {
      return this.getSpecialHours();
    } else {
      return this.getTodaysWorkingConfig();
    }
  }

  updateHolidayStatus(date: string, isOpen: boolean, from_hour: number, from_min: number, to_hour: number, to_min: number) {
    let holiday = this.holiday_list.find(h => h.holiday_date === date);
    if (holiday) {
      holiday.is_open = isOpen;
      holiday.hour = {
        from_hour: from_hour,
        from_min: from_min,
        to_hour: to_hour,
        to_min: to_min
      };
    } else {
      this.holiday_list.push({
        holiday_date: date,
        is_open: isOpen,
        hour: {
          from_hour: from_hour,
          from_min: from_min,
          to_hour: to_hour,
          to_min: to_min
        }
      });
    }
  }
}

export class WorkingDay {
  is_open: boolean;
  from_hour: number;
  from_min: number;
  to_hour: number;
  to_min: number;
  constructor(is_open: boolean, from_hour: number, from_min: number, to_hour: number, to_min: number) {
    this.is_open = is_open
    this.from_hour = from_hour
    this.from_min = from_min
    this.to_hour = to_hour
    this.to_min = to_min
  }
}

export class StoreMenuGroup {
  group_name: string;
  items: MenuItem[];
}
export class StoreMenuItems {
  menu_items: StoreMenuGroup[];
  store: Store;
  menu_order: string[];
}

export class MenuItemGroup {
  id: number;
  name: string;
  description: string;
  starting_price: number;
  image: string;
}
export class StoreMenuItemsV2 {
  menu_items: Map<string, MenuItemGroup>;
  store: Store;
  menu_order: string[];
}

export interface Table {
  id: number;
  table_name: string;
}

export interface StoreLite {
  id: number;
  store_name: string;
}
